import SimpleLightbox from 'simplelightbox';

const thumbnailAnchors = document.querySelectorAll(
    '.inspiration-thumbnail-anchor'
);

if (thumbnailAnchors) {
    const lightboxGallery = new SimpleLightbox(
        `.inspiration-thumbnail-anchor`,
        {}
    );
}
